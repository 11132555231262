import React, { useContext } from "react";
import { SuperadminBillsContext } from "../../context";
import { useStateManager } from "hooks/use-state-manager";
import { Checkbox } from "semantic-ui-react";
import CurrencyInput from "react-currency-input-field";
import { Dropdown } from "components/dropdown";

const LinesFormModel = ({ className, name, id }) => {
  const { state, changeState } = useContext(SuperadminBillsContext);
  const docTypeOptions = [
    { key: "V", text: "V.", value: "V" },
    { key: "E", text: "E.", value: "E" },
    { key: "J", text: "J.", value: "J" },
    { key: "G", text: "G.", value: "G" },
  ];
  const initialState = {
    id,
    name,
    description: "",
    qty: "",
    amount_bs: 0,
    amount_usd: 0,
    is_exempt: false,
    code: "",
    isActive: true,
    is_third_part: false,
    third_part_doc_id: "",
    third_part_doc_type: "",
    third_part_name: "",
  };
  const [formState, changeFormState] = useStateManager(initialState);

  const updateParentState = (obj) => {
    changeState({
      lines: state.lines.map((line) => {
        return line.id !== id ? line : obj;
      }),
    });
  };
  const onChangeAmountUSD = (value) => {
    const numberValue = parseFloat(value ? value.replace(",", ".") : "0");
    const stateRecovery = {
      ...formState,
      amount_usd: value,
      amount_bs: (numberValue * state.exchange_usd_to_bs).toFixed(5).toString(),
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };
  const onChangeAmountVES = (value) => {
    const stateRecovery = {
      ...formState,
      amount_bs: value,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };

  const onChangeQty = (event) => {
    const value = event.target.value.replace(/[^0-9\s]/g, "");

    const stateRecovery = {
      ...formState,
      qty: value,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };
  const onChangeDescription = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    if (key === "code") {
      const stateRecovery = {
        ...formState,
        code: value.replace(/[^0-9\s]/g, ""),
      };
      changeFormState(stateRecovery);
      updateParentState(stateRecovery);
    } else {
      const stateRecovery = {
        ...formState,
        [key]: value,
      };
      changeFormState(stateRecovery);
      updateParentState(stateRecovery);
    }
  };

  const onExemptChange = (event, data) => {
    const stateRecovery = {
      ...formState,
      is_exempt: data.checked,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };

  const onChangeDoc = (event, data) => {
    const isDocType = data && data.name && data.name === "third_part_doc_type";

    const key = isDocType ? data.name : event.target.name;
    const value = isDocType ? data.value : event.target.value;
    if (key === "third_part_doc_id") {
      let newValue = value.replace(/[^0-9\s]/g, "");
      newValue = newValue.replace(" ", "");
      const stateRecovery = {
        ...formState,
        [key]: newValue,
      };
      changeFormState(stateRecovery);
      updateParentState(stateRecovery);
    } else {
      changeFormState({ [key]: value });
    }
  };
  return (
    <div className={className}>
      <p className={"title"}>{"Operación"}</p>
      <form>
{/*        <section className="check-box">
          <Checkbox
            toggle
            checked={formState.isActive}
            label="Editar"
            onChange={(event, data) => {
              const stateRecovery = {
                ...formState,
                isActive: data.checked,
              };
              changeFormState(stateRecovery);
              updateParentState(stateRecovery);
            }}
          />
        </section>*/}
        {state.fiscalForm.has_third_part && (
          <section className="check-box">
            <Checkbox
              toggle
              checked={formState.is_third_part}
              label="Recaudación de terceros"
              onChange={(event, data) => {
                const stateRecovery = {
                  ...formState,
                  is_third_part: data.checked,
                  third_part_doc_id: "",
                  third_part_doc_type: "",
                  third_part_name: "",
                };
                changeFormState(stateRecovery);
                updateParentState(stateRecovery);
              }}
            />
          </section>
        )}
        {formState.is_third_part && (
          <>
            <section className="description">
              <label htmlFor="third_part_name">A nombre de:</label>
              <input
                type="text"
                value={formState.third_part_name}
                onChange={onChangeDescription}
                name="third_part_name"
                required
              />
            </section>
            <div className="columns-inputs">
              <div className="column">
                <section className="content">
                  <label htmlFor="third_part_doc_type">
                    Tipo de documento:
                  </label>
                  <Dropdown
                    fluid
                    floating
                    selection
                    id="third_part_doc_type"
                    value={formState.third_part_doc_type}
                    onChange={onChangeDoc}
                    options={docTypeOptions}
                    className="doc-type"
                    name={"third_part_doc_type"}
                    required
                  />
                </section>
              </div>
              <div className="column">
                <section className="content">
                  <label htmlFor="third_part_doc_id">Nro de documento:</label>
                  <input
                    id="third_part_doc_id"
                    type="text"
                    name="third_part_doc_id"
                    maxLength={16}
                    required
                    autoComplete="off"
                    placeholder="Número de documento"
                    value={formState.third_part_doc_id}
                    onChange={onChangeDoc}
                    className="doc-in"
                  />
                </section>
              </div>
            </div>
          </>
        )}
        <div className="columns-inputs">
          <div className="column">
            <section className="content">
              <label htmlFor="qty">Cantidad:</label>
              <input
                type="text"
                value={formState.qty}
                onChange={onChangeQty}
                name="qty"
                required
                disabled={!formState.isActive}
              />
            </section>
            <section className="content">
              <label htmlFor="code">Código:</label>
              <input
                type="text"
                value={formState.code}
                //value={names[name].code}
                onChange={onChangeDescription}
                name="code"
                maxLength={6}
                required
                disabled={!formState.isActive}
                //disabled
              />
            </section>
          </div>
          <div className="column">
            <section className="content">
              <label htmlFor="amount_bs">Monto en VES por unidad:</label>
              <CurrencyInput
                prefix={"Bs. "}
                value={formState.amount_bs}
                defaultValue={0}
                decimalsLimit={5}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={onChangeAmountVES}
                name="amount_bs"
                required
                disabled={
                  !formState.isActive || state.fiscalForm.currency === "USD"
                }
              />
            </section>
            <section className="content">
              <label htmlFor="amount_usd">Monto en USD:</label>
              <CurrencyInput
                prefix={"$ "}
                value={formState.amount_usd}
                defaultValue={0}
                decimalsLimit={5}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={onChangeAmountUSD}
                name="amount_usd"
                required
                disabled={!formState.isActive}
              />
            </section>
          </div>
        </div>
        <section className="description">
          <label htmlFor="description">Descripción:</label>
          <input
            type="text"
            value={formState.description}
            onChange={onChangeDescription}
            name="description"
            required
          />
        </section>
        <section className="exempt">
          <Checkbox
            checked={formState.is_exempt}
            label="Exento"
            onChange={onExemptChange}
          />
        </section>
      </form>
    </div>
  );
};

export default LinesFormModel;
