import React, { useContext } from "react";
import { SuperadminBillsContext } from "../../context";
import { Button } from "components/buttons";
import { Panel } from "components/panel";
import { LinesForm } from "../lines-form";
import { Icon } from "semantic-ui-react";
import { grey_4 } from "assets/strings/colors";
import { billingComponentServices } from "../../services";
import { Loader } from "components/loader";
import { newRandomListKey } from "services/utils";

const iconStyle = {
  paddingLeft: "2rem",
  paddingTop: "1rem",
  display: "flex",
  alignItems: "left",
  float: "left",
  color: grey_4,
  cursor: "pointer",
  zIndex: 1,
};
const OperationsFormModel = ({ className }) => {
  const { state, changeState, throwPopupMessage } = useContext(
    SuperadminBillsContext
  );
  const { loading, setArgs, setActivateFetch } =
    billingComponentServices.usePreviewBill();
  const onSubmit = (event) => {
    event.preventDefault();
    let errorExist = false;
    let errorMessage = "";
    const validLines = state.lines.filter((line) => line.isActive);
    const lines = validLines.map((line) => {
      const {
        description,
        qty,
        amount_bs,
        amount_usd,
        is_exempt,
        code,
        is_third_part,
        third_part_name,
        third_part_doc_id,
        third_part_doc_type,
      } = line;

      return {
        description,
        qty: parseInt(qty),
        amount_bs: parseFloat(amount_bs.toString().replace(",", ".")),
        amount_usd: parseFloat(amount_usd.toString().replace(",", ".")),
        is_exempt,
        code,
        is_third_part,
        third_part_name,
        third_part_doc_id,
        third_part_doc_type,
      };
    });

    for (const data of lines) {
      const {
        description,
        qty,
        code,
        is_third_part,
        third_part_name,
        third_part_doc_id,
        third_part_doc_type,
      } = data;
      if (
        is_third_part &&
        (!third_part_name || !third_part_doc_id || !third_part_doc_type)
      ) {
        errorExist = true;
        errorMessage = "Los datos de recaudación a terceros no son válidos";
        break;
      }
      if (!qty) {
        errorExist = true;
        errorMessage = "Debe ingresar una cantidad distinta de 0";
        break;
      }

      if (!code || code === "000001" || code === "000002") {
        errorExist = true;
        errorMessage = "El código no es válido";
        break;
      }
      if (!description) {
        errorExist = true;
        errorMessage = "Debe ingresar una descripción";
        break;
      }
    }

    if (errorExist) {
      throwPopupMessage("error", errorMessage);
      return;
    }

    const fiscal = {
      ...state.fiscalForm,
      lines,
      exchange_usd: parseFloat(state.exchange_usd_to_bs),
    };
    setArgs(fiscal);
    setActivateFetch(true);
  };

  if (loading) return <Loader />;
  return (
    <Panel>
      <Icon
        style={iconStyle}
        name="arrow left"
        onClick={(event) => {
          event.preventDefault();
          changeState({ showNextPage: false });
        }}
      />
      {state.lines &&
        Array.isArray(state.lines) &&
        state.lines.map((line) => {
          return (
            <LinesForm
              name={line && line.name ? line.name : "operation"}
              key={line && line.id ? line.id : ""}
              id={line && line.id ? line.id : ""}
            />
          );
        })}
      <Button
        onClick={() => {
          changeState({
            lines: [
              ...state.lines,
              {
                id: newRandomListKey(),
                name: "operation",
                description: "",
                qty: 0,
                amount_bs: 0,
                amount_usd: 0,
                is_exempt: false,
                code: "",
                isActive: true,
                is_third_part: false,
                third_part_doc_id: "",
                third_part_doc_type: "",
                third_part_name: "",
              },
            ],
          });
        }}
        color="gray"
      >
        Agregar línea
      </Button>
      <Button onClick={onSubmit} color="blue">
        Previsualización
      </Button>
    </Panel>
  );
};

export default OperationsFormModel;
