import LinesFormModel from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

export const LinesForm = styled(LinesFormModel)`
  #root & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    > .title {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${grey_6};
    }

    > form {
      width: 80%;
      > .check-box {
        padding: 1rem 0;
      }
      > .columns-inputs {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        > .column {
          width: calc(50% - 1rem);
          > .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            label {
              padding-bottom: 2px;
            }
            input {
              margin-bottom: 5px;
              border: none;
              border-bottom: 1px solid ${grey_4};
              border-radius: 0.8rem;
              text-align: start;
              width: 100%;
              height: 3rem;
              padding: 0 1rem;
              :focus {
                outline: none;
              }
              :disabled {
                color: ${grey_4};
              }
            }
          }
        }
      }
      > .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        label {
          padding-bottom: 2px;
        }
        input {
          margin-bottom: 5px;
          border: none;
          border-bottom: 1px solid ${grey_4};
          border-radius: 0.5rem;
          text-align: start;
          width: 100%;
          height: 3rem;
          padding: 1rem 1rem;
          :focus {
            outline: none;
          }
        }
      }
      > .exempt {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        margin: 0.3rem 0;
        input {
          cursor: pointer;
          margin-right: 10px;
          border: 1px solid ${grey_4};
          border-radius: 1rem;
          text-align: center;
          width: 15px;
          height: 15px;
          :focus {
            outline: none;
          }
        }
      }
    }
  }
`;
