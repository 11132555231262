import React, { useContext, useEffect } from "react";
import { SuperadminBillsContext } from "../../context";
import { useStateManager } from "hooks/use-state-manager";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { Panel } from "components/panel";
import { Icon } from "semantic-ui-react";
import CurrencyInput from "react-currency-input-field";

import { grey_4 } from "assets/strings/colors";

const iconStyle = {
  paddingLeft: "2rem",
  paddingTop: "1rem",
  display: "flex",
  alignItems: "left",
  float: "left",
  color: grey_4,
  cursor: "pointer",
  zIndex: 1,
};

const FiscalFormModel = ({ className }) => {
  const { state, changeState, viewport } = useContext(SuperadminBillsContext);
  const initialFormState = {
    billing_fiscal_id: "",
    alias: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    currency: "VES",
    doc_id: "",
    doc_type: "",
    save_billing_fiscal_info: false,
    apply_igtf: false,
    igtf_percentage: 3,
    has_third_part: false,
  };
  const docTypeOptions = [
    { key: "V", text: "V.", value: "V" },
    { key: "E", text: "E.", value: "E" },
    { key: "J", text: "J.", value: "J" },
    { key: "G", text: "G.", value: "G" },
  ];
  const currencyOptions = [
    { key: "VES", text: "VES", value: "VES" },
    { key: "USD", text: "USD", value: "USD" },
  ];

  const [formState, changeFormState] = useStateManager({
    ...initialFormState,
    ...state.fiscalForm,
  });

  useEffect(() => {
    if (state.fiscalForm) {
      changeFormState({
        ...initialFormState,
        ...state.fiscalForm,
      });
    }
  }, [state.fiscalForm]);

  useEffect(() => {
    if (state.cleanUp) {
      changeFormState(initialFormState);
      changeState({
        selectedFiscalForm: null,
        fiscalForm: {
          ...state.fiscalForm,
          ...initialFormState,
        },
        cleanUp: false,
      });
    }
  }, [state.cleanUp]);

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({
      fiscalForm: { ...formState },
      showNextPage: true,
    });
  };

  const onChangeInputs = (event, data) => {
    const isDocType = data && data.name && data.name === "doc_type";
    const isCurrency = data && data.name && data.name === "currency";

    const key = isDocType || isCurrency ? data.name : event.target.name;
    const value = isDocType || isCurrency ? data.value : event.target.value;

    if (key === "phone" || key === "doc_id") {
      let newValue = value.replace(/[^0-9\s]/g, "");
      newValue = newValue.replace(" ", "");
      changeFormState({ [key]: newValue });
    } else {
      changeFormState({ [key]: value });
    }
    if (isCurrency) {
      changeFormState({
        apply_igtf: false,
        igtf_percentage: 3,
      });
    }
  };

  const onChangeExchange = (value) => {
    changeState({ exchange_usd_to_bs: value });
  };

  const cleanUp = (event) => {
    event.preventDefault();
    changeState({
      cleanUp: true,
    });
  };

  const selectCurrentExchange = (event) => {
    event.preventDefault();
    changeState({ exchange_usd_to_bs: state.currentExchange.toString() });
  };

  return (
    <Panel>
      {viewport === "phone" && (
        <Icon
          style={iconStyle}
          name="arrow left"
          onClick={(event) => {
            event.preventDefault();
            changeState({ showFormOnMobile: false });
          }}
        />
      )}
      <div className={className}>
        <form onSubmit={onSubmit}>
          <section className="title">Nueva factura</section>
          <section className="content">
            <label htmlFor="exchange">Tasa contemplada:</label>
            <div className="rate">
              <CurrencyInput
                prefix={"Bs. "}
                value={state.exchange_usd_to_bs}
                defaultValue={0}
                decimalsLimit={5}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={onChangeExchange}
                name="exchange"
                required
              />
              <Button onClick={selectCurrentExchange} width="25%" color="green">
                Usar tasa por defecto
              </Button>
            </div>
          </section>
          <section className="content-inverted">
            <label htmlFor="save_billing_fiscal_info">
              {"Involucra a terceros"}
            </label>
            <input
              type="checkbox"
              value={formState.has_third_part}
              onChange={() =>
                changeFormState({
                  has_third_part: !formState.has_third_part,
                  apply_igtf: initialFormState.apply_igtf,
                  igtf_third_part: initialFormState.igtf_percentage,
                })
              }
            />
          </section>
          <section className="content">
            <label htmlFor="doc_id">Documento:</label>
            <div className="document">
              <Dropdown
                fluid
                floating
                selection
                id="doc-type"
                value={formState.doc_type}
                onChange={onChangeInputs}
                options={docTypeOptions}
                className="doc-type"
                name={"doc_type"}
                required
              />
              <input
                id="doc-id"
                type="text"
                name="doc_id"
                maxLength={16}
                required
                autoComplete="off"
                placeholder="Número de documento"
                value={formState.doc_id}
                onChange={onChangeInputs}
                className="doc-in"
              />
            </div>
          </section>
          <section className="content">
            <label htmlFor="name">Nombre:</label>
            <input
              type="text"
              value={formState.name}
              onChange={onChangeInputs}
              name="name"
              placeholder="ej: Corporación Paytech, C.A."
            />
          </section>
          <section className="content">
            <label htmlFor="alias">Alias:</label>
            <input
              type="text"
              value={formState.alias}
              onChange={onChangeInputs}
              name="alias"
              placeholder="ej: PagoDirecto"
            />
          </section>
          <section className="content">
            <label htmlFor="address">Dirección:</label>
            <input
              type="text"
              value={formState.address}
              onChange={onChangeInputs}
              name="address"
              placeholder="ej: Caracas, C.C.C.T. ... "
              required
            />
          </section>
          <section className="content">
            <label htmlFor="email">Correo electrónico:</label>
            <input
              type="text"
              value={formState.email}
              onChange={onChangeInputs}
              name="email"
              placeholder="ej: ejemplo@mail.com"
              required
            />
          </section>
          <section className="content">
            <label htmlFor="phone">Número de teléfono:</label>
            <input
              type="text"
              maxLength={11}
              minLength={11}
              required
              value={formState.phone}
              onChange={onChangeInputs}
              name="phone"
              placeholder="ej: 04121100220"
            />
          </section>
          <section className="selection">
            <label htmlFor="currency">Moneda:</label>
            <Dropdown
              fluid
              floating
              selection
              id="doc-currency"
              value={formState.currency}
              onChange={onChangeInputs}
              options={currencyOptions}
              className="currency"
              name="currency"
              required
            />
          </section>
          {!formState.has_third_part && formState.currency === "USD" && (
            <>
              <section className="content-inverted">
                <label htmlFor="save_billing_fiscal_info">
                  {"Aplicar IGTF"}
                </label>
                <input
                  type="checkbox"
                  value={formState.apply_igtf}
                  onChange={() =>
                    changeFormState({
                      apply_igtf: !formState.apply_igtf,
                    })
                  }
                />
              </section>
              {formState.apply_igtf && (
                <section className="content">
                  <label htmlFor="phone">Porcentaje IGTF:</label>
                  <input
                    type="text"
                    maxLength={11}
                    minLength={11}
                    required
                    value={formState.igtf_percentage}
                    onChange={onChangeInputs}
                    name="igtf_percentage"
                    placeholder="ej: 4"
                  />
                </section>
              )}
            </>
          )}
          <section className="content-inverted">
            <label htmlFor="save_billing_fiscal_info">
              {"Guardar en el directorio"}
            </label>
            <input
              type="checkbox"
              value={formState.save_billing_fiscal_info}
              onChange={() =>
                changeFormState({
                  save_billing_fiscal_info: !formState.save_billing_fiscal_info,
                })
              }
            />
          </section>
          <section className="submit">
            <Button color="grey" width="30%" onClick={cleanUp}>
              Limpiar
            </Button>
            <Button
              color="blue"
              width="30%"
              disabled={!formState.currency || !formState.doc_type}
            >
              Siguiente
            </Button>
          </section>
        </form>
      </div>
    </Panel>
  );
};

export default FiscalFormModel;
