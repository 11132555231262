import OperationsFormModel from "./model";
import styled from "styled-components";
import { grey_6, grey_4 } from "assets/strings/colors";

export const OperationsForm = styled(OperationsFormModel)`
  #root & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    > form {
      width: 80%;
      > .title {
        padding-top: 10px;
        width: 100%;
        font-size: 1.3rem;
        font-weight: bold;
        color: ${grey_6};
        text-align: center;
        text-transform: uppercase;
      }

      > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        label {
          padding-bottom: 2px;
        }

        input {
          margin-bottom: 5px;
          border: none;
          border-bottom: 1px solid ${grey_4};
          border-radius: 0.5rem;
          text-align: start;
          width: 100%;
          height: 3rem;
          padding: 1rem 1rem;
          :focus {
            outline: none;
          }
        }
        > .document {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          > .doc-type {
            width: 20%;
          }
          input {
            width: 75%;
          }
        }
      }
      > .content-inverted {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        margin: 1rem 0;
        input {
          cursor: pointer;
          margin-right: 10px;
          border: 1px solid ${grey_4};
          border-radius: 1rem;
          text-align: center;
          width: 15px;
          height: 15px;
          :focus {
            outline: none;
          }
        }
      }
      > .submit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
